<template>
  <div class="user">
    <div class="user__company__info">
      <data-card-small
          theme="red"
          :class="'small'"
          :text="$t('users.licence')"
          :value="activeLicenses"
        >
         <alert-outline class="user__company__info-icon" />
      </data-card-small>
      <data-card-small
        theme="blue"
         size="small"
        :text="$t('filter.agents')"
        :value="agents"
      />
      <data-card-small
        theme="blue"
        size="small"
        :text="$t('team.administrators')"
        :value="admins"
      />
      <data-card-small
        theme="blue"
        size="small"
        :text="$t('team.super-admin')"
        :value="supers"
      />
    </div>
    <div>
      <hcc-button
      size="lg"
      variant="transparent"
      color="secondary"
      @click="openChatCenter"
    >
     {{ $t('app.request-licenses') }}
    </hcc-button>
    </div>
    <div class="user__filter">
      <p
        class="user__company__info licence"
        v-if="activeLicenses <= 0 && !isOwner"
      >
        {{ $t("users. licenses-legend") }}
      </p>
      <template>
        <button-filter :display="enabledFilters" @change="onFiltersChanged">
          <hcc-select
            :placeholder="'Role'"
            custom-class="select"
            v-model="roleSelected"
            :options="allRoles"
            optionLabel="display_name"
          />
        </button-filter>
      </template>
    </div>
    <filters :display="enabledFilters" />
    <section class="main-content">
      <tabs>
        <tab
          v-if="isOwner"
          key="owner"
          icon="account-multiple-icon"
          :name="$t('users.owner-tab')"
          :selected="true"
        >
          <owner />
        </tab>
        <tab
          v-for="(tab, index) in tabs"
          :key="index"
          :icon="tab.icon"
          :name="tab.title"
          :selected="tab.selected"
        >
          <component
            :isOwner="isOwner"
            :licences="total > 0"
            :is="tab.component"
            :filters="filters"
            :role="roleSelected"
          ></component>
        </tab>
      </tabs>
    </section>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapGetters, mapMutations } from 'vuex';
import { totalAdminSuper } from '@/utils/userByCompany';
import userActions from '@/graphql/mutations/userActions.gql';
import listRoles from '@/graphql/queries/users/listRoles.gql';
import EventBus from '@/eventBus';
import { UPDATE_COMPANY_USERS, UPDATE_USERS_OWNERS, NEW_USER } from '@/eventTypes';
import errorHandler from '@/utils/error.handler';
import Filters from '@/components/Filters.vue';


export default {
  components: {
    Filters,
    DataCardSmall: () => import('@/components/DataCardSmall.vue'),
    ButtonFilter: () => import('@/components/ButtonFilter.vue'),
    HccSelect: () => import('@/components/shared/HccSelect/index.vue'),
    HccButton: () => import('@/components/shared/HccButton/index.vue'),
    User: () => import('@/components/users/User.vue'),
    Owner: () => import('@/components/users/Owner.vue'),
    Tabs: () => import('@/components/TabsContainer.vue'),
    Tab: () => import('@/components/TabsSingle.vue'),
  },
  data() {
    return {
      filter: false,
      roleSelected: null,
      takenLicenses: 0,
      supers: 0,
      admins: 0,
      filters: null,
      enabledFilters: ['campaign'],
    };
  },
  created() {
    EventBus.$on(NEW_USER, ({ payload, role }) => {
      this.updateUsersCompany(payload, role);
      this.getCompanyLicences();
    });
  },
  apollo: {
    roles() {
      return {
        query: gql`${listRoles}`,
        variables() {
          return {
            company: this.currentCompanyId,
          };
        },
        skip() {
          return !this.currentCompanyId;
        },
        update: ({ listRoles: roles }) => {
          this.setRoles(roles);
          return roles;
        },
        error(err) {
          this.networkErr(err);
          this.$toasted.global.error(this.$t('alerts.unexpectedError'));
        },
      };
    },
  },
  computed: {
    ...mapGetters({
      isOwner: 'shared/isOwner',
      companies: 'shared/getFilterCompanies',
    }),
    activeLicenses() {
      return this.currentCompany ? this.currentCompany.maxUsers : 0;
    },
    total() {
      return this.activeLicenses - this.takenLicenses;
    },
    currentCompanyId() {
      return this.isOwner ? this.filterCompany : this.userCompany.id;
    },
    currentCompany() {
      return this.companies.find(({ id }) => id === this.currentCompanyId);
    },
    userCompany() {
      return JSON.parse(localStorage.currentCompany);
    },
    filterCompany() {
      return this.filters ? this.filters.companyId : null;
    },
    agents() {
      return this.activeLicenses - this.takenLicenses;
    },
    tabs() {
      return [{
        icon: 'account-icon',
        title: this.$t('users.users-tab'),
        selected: !this.isOwner,
        component: 'user',
      }];
    },
    allRoles() {
      return [{
        display_name: 'Todos',
        id: 4,
      }].concat(this.roles);
    },
    supportUrl() {
      return process.env.VUE_APP_SUPPORT;
    },
  },
  watch: {
    filters(newValue, oldValue) {
      if (newValue !== null && (oldValue === null || newValue.companyId !== oldValue.companyId)) {
        this.getCompanyLicences();
      }
    },
  },
  mounted() {
    if (this.isOwner) {
      this.enabledFilters.push('company');
    }
    [this.roleSelected] = this.allRoles;
  },
  methods: {
    ...mapMutations({ setRoles: 'shared/setRoles' }),
    async getCompanyLicences() {
      const { admins, supers } = await totalAdminSuper(this.currentCompanyId);
      this.supers = supers;
      this.admins = admins;
      this.takenLicenses = admins + supers;
    },
    async updateUsersCompany(payload, role) {
      try {
        await this.$apollo.mutate({
          mutation: userActions,
          variables: payload,
        });

        if (role === 'owner') {
          EventBus.$emit(UPDATE_USERS_OWNERS);
        } else {
          EventBus.$emit(UPDATE_COMPANY_USERS);
        }
      } catch (error) {
        this.$toasted.global.error(this.$t('users.errors.repeated-email'));
        errorHandler.logErrors(error.message);
      }
    },
    onFiltersChanged(filters) {
      this.filters = filters;
    },
    openChatCenter() {
      window.open(this.supportUrl, '_blank').focus();
    },
  },
};

</script>

<style scoped lang="scss">
@import "~styles/components/_campaign-configuration.scss";
@import "~styles/views/_user.scss";
</style>
